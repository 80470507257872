import React from "react";
import { FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
// import logo from "../../assect/images/Logo/new_logo.png";

const HomePage = () => {
  const handleRedirect = (url) => {
    window.location.href = url;
  };

  return (
    <div className="main-page homepage-container">
      <div>
        <div className="content">
          {/* <div className="circle mb-4">
            <span className="circle-text">HONEYED</span>
          </div> */}
          {/* <img src={logo} alt="PierTop Logo" className="logo mb-4" />{" "} */}
          <h2 className="mb-3">PierTop</h2>
          {/* <p className="mb-4">Good pastry for a good day</p> */}
          <button
            className="btn btn-outline-primary w-100 mb-3"
            onClick={() => handleRedirect("https://piertoprealestate.com/")}
          >
            PierTop Real Estate
          </button>
          <button
            className="btn btn-outline-primary w-100 mb-3"
            onClick={() =>
              handleRedirect("https://piertopchurchrealestate.com/")
            }
          >
            PierTop Church Real Estate
          </button>
          <button
            className="btn btn-outline-primary w-100 mb-3"
            onClick={() => handleRedirect("https://piertopinsurance.com/")}
          >
            PierTop Insurance
          </button>
          {/* <button className="btn btn-outline-primary w-100 mb-4">
            Locations
          </button> */}
          {/* <div className="d-flex justify-content-center mb-4">
            <FaInstagram className="mx-2" size={30} />
            <FaYoutube className="mx-2" size={30} />
            <FaTwitter className="mx-2" size={30} />
          </div> */}
          {/* <img
            src="path/to/croissant-image.png"
            alt="Croissant"
            className="img-fluid rounded"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
